import React, { useMemo } from "react";
import { graphql,useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import GithubRepoCard from "../components/GithubRepoCard";
import { SnakeGame } from "react-snake-game";
import useMediaQuery from "../hooks/useMediaQuery";
import { MEDIA_QUERY } from "../const/media-queries";

const OpenSourcePage = () => {

    // Github GraphQL query
    const data = useStaticQuery(graphql`
    query {
        github {
            search(query: "is:public org:anomaly", type: REPOSITORY, first: 10) {
                repositoryCount
                edges {
                    node {
                        ... on Github_Repository {
                            name
                            url
                            description
                            forkCount
                            licenseInfo {
                                name
                            }
                            watchers {
                                totalCount
                            }
                            mentionableUsers(last: 10) {
                                totalCount
                                nodes {
                                name
                                avatarUrl
                                }
                            }
                            stargazers {
                                totalCount
                            }
                            languages(last: 10) {
                                nodes {
                                name
                                color
                                }
                            }
                            releases(last: 1) {
                                nodes {
                                name
                                }
                            }                  
                        }
                    }
                }
            }
        }
    }
    `);

    const {
        edges
    } = data.github.search;

    // MARK: - Snake game state
    const isMD = useMediaQuery(MEDIA_QUERY.SCREENS.MD);
    const isLG = useMediaQuery(MEDIA_QUERY.SCREENS.LG);

    const numOfTiles = useMemo(() => {
        let numOfTilesX = 60;
        let numOfTilesY = 20;
        let tileSize = 20;

        if (isMD) {
            numOfTilesX = 35;
            numOfTilesY = 15;
            tileSize = 15;
        }

        if (isLG) {
            numOfTilesX = 60;
            numOfTilesY = 10;
            tileSize = 10;
        }

        return {
            numOfTilesX,
            numOfTilesY,
            tileSize,
        };
    }, [isMD, isLG]);

    const GameInstructions = () => {
        return(
            <div className="hidden mx-auto my-8 font-mono text-base uppercase lg:block text-gamepad-text">
                <p className="text-center">
                    Press{' '}
                    <span className="px-6 py-2 border rounded bg-gamepad-background border-gamepad-border">Space</span>
                    {' '}to begin or pause{' '}
                    <span aria-label="left arrow" role="img" className="px-2 py-1 mr-2 border rounded bg-gamepad-background border-gamepad-border">←</span>
                    <span aria-label="right arrow" role="img" className="p-2 py-1 mr-2 border rounded bg-gamepad-background border-gamepad-border">→</span>
                    <span aria-label="up arrow" role="img" className="p-2 py-1 mr-2 border rounded bg-gamepad-background border-gamepad-border">↑</span>
                    <span aria-label="down arrow" role="img" className="p-2 py-1 mr-2 border rounded bg-gamepad-background border-gamepad-border">↓</span>
                    {' '}to control the snake. Have fun{' '}
                    <span aria-label="snake icon" role="img">🐍</span>
                </p>
            </div>
        );
    }

    return (
        <Layout>
            <Seo
                title="Open Source"
                description="Our contributions to vast sea of software resources that lets us build upon the shoulders of giants"
            />
            <div className="mx-4 lg:max-w-screen-lg lg:mx-auto"> 
               <h1 className="text-5xl font-bold text-anomaly-red">Open Source</h1>
               <h2 className="mt-4 mb-8 text-2xl font-medium text-gray-500">The secret impresses no one. The trick you use it for is everything.</h2>
            </div>
            <div className="flex justify-center hidden w-full bg-white lg:block">
                <SnakeGame
                    className="container"
                    smoothAnimations={false}
                    speed={18}
                    multiplier={3}
                    tileColor="#fff"
                    borderColor="#efefef"
                    snakeColors={["#216e39", "#30a14e", "#40c463", "#9be9a8"]}
                    appleColor="#D00000"
                    {...numOfTiles}
                />
            </div>
            <GameInstructions/>

            <ul className="grid max-w-screen-lg grid-cols-1 gap-8 mx-4 lg:mx-auto lg:grid-cols-2">
                {edges.map(({ node }) => {
                    return(
                        <GithubRepoCard repository={node} key={node.name} />
                    );
                })}
            </ul>


        </Layout>
    );
};

export default OpenSourcePage;
