import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faBinoculars, faCodeMerge } from '@fortawesome/pro-light-svg-icons'

/**
 * Returns a stylised Github Repo Card
 * 
 * The component is passed in a result of the Github GraphQL query
 * which is executed by the open source page and passed in as a prop
 * 
 * @param {repository} 
 * @returns {JSX.Element}
 */
const GithubRepoCard = ({repository}) => {

    /**
     * @description - unpack the information used in the repository card
     */
    const {
        watchers,
        url,
        stargazers,
        mentionableUsers,
        languages,
        releases,
        description,
        name
    } = repository;

    console.log(repository);
    console.log(releases);

    /**
     * Return a list of languages used in the repository
     * @returns {JSX.Element}
     */
    const LanguageMap = () => {
        return(
        <ul className="flex my-10 text-gray-700">
        {languages.nodes.map((language, index) => {
        return(
            <li key={index} className="flex items-center mr-2">
                <div className={`w-3 h-3 mr-2 rounded-full bg-[${language.color}]`}></div>
                <p>{language.name}</p>
            </li>
        );
        })}
        </ul>
        );
    };
    
    /**
     * Return a list of contributors to the repository
     * @returns {JSX.Element}
     */
    const ContributorAvatar = () => {
        return(
        <ul className="flex">
        {mentionableUsers.nodes.map((user, index) => {
        return(
        <li key={index}>
            <img 
            className="border-2 border-gray-200 rounded-full" height={40} width={40} 
            key={index} 
            src={user.avatarUrl} 
            alt={user.name}/>
        </li>
        );
        })}
        </ul>
        );
    };

    /**
     * Returns a list of repository
     */
    return(
        <li className="p-4 text-gray-500 bg-white shadow-2xl rounded-2xl">
            <aside className="flex justify-between mb-2">
                <h3 className="text-2xl">
                    <a href={url}>
                        <span className="text-purple-700">{' '}{name}</span>
                    </a>
                </h3>
                <span className="flex items-center justify-center">
                    <FontAwesomeIcon size="1x" className="mr-2 fill-current" icon={faStar} />
                    <p className="mr-2">{stargazers.totalCount}</p>
                    <FontAwesomeIcon size="1x" className="mr-2 fill-current" icon={faBinoculars} />                    
                    <p className="mr-2">{watchers.totalCount}</p>
                </span>
            </aside>
            <p>{description}</p>
            <LanguageMap/>
            <div className="flex justify-between mt-10">
                <ContributorAvatar />
                <span className="flex items-center justify-center">
                    <FontAwesomeIcon size="1x" className="mr-2 fill-current" icon={faCodeMerge} />
                    {/* <p className="mr-2">{releases.nodes[0].name}</p> */}
                </span>
            </div>
        </li>
    );


};

export default GithubRepoCard;
