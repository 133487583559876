import { useEffect, useMemo, useState } from "react";

const useMediaQuery = (query) => {

    const mediaQuery = useMemo(() => (typeof window !== `undefined`) ? window?.matchMedia(query):null, [query]);
    const [match, setMatch] = useState(!! (typeof window !== `undefined`) ? window.matchMedia(mediaQuery).matches:false);

    useEffect(() => {
        const handler = () => setMatch(mediaQuery?.matches);

        mediaQuery.addEventListener("change", handler);

        return () => {
            mediaQuery.removeEventListener("change", handler);
        };
    }, [mediaQuery]);

    return match;
};

export default useMediaQuery;
