const BREAKPOINTS = {
    SM: "640px",
    MD: "768px",
    LG: "1024px",
    XL: "1280px",
    "2XL": "1536px",
};

const widthMediaQuery = (prefix, breakpoint) =>
    `(${prefix}-width: ${breakpoint})`;

export const MEDIA_QUERY = {
    SCREENS: {
        SM: widthMediaQuery("min", BREAKPOINTS.SM),
        MD: widthMediaQuery("min", BREAKPOINTS.MD),
        LG: widthMediaQuery("min", BREAKPOINTS.LG),
        XL: widthMediaQuery("min", BREAKPOINTS.XL),
        "2XL": widthMediaQuery("max", BREAKPOINTS["2XL"]),
        MAX_SM: widthMediaQuery("max", BREAKPOINTS.SM),
        MAX_MD: widthMediaQuery("max", BREAKPOINTS.MD),
        MAX_LG: widthMediaQuery("max", BREAKPOINTS.LG),
        MAX_XL: widthMediaQuery("max", BREAKPOINTS.XL),
        MAX_2XL: widthMediaQuery("max", BREAKPOINTS["2XL"]),
    },
};
